body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000;
  color: #fff;
  padding-bottom: 150px;
  background: #00151A;
  padding-top: 150px;
  width: 100vw;
  min-height: 100vh;
  padding-left: 85px;
  padding-right: 20px;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.clicky {
  cursor: pointer;
}

@media (max-width: 575.98px ) { 
  body{
    padding-left: 20px;
  }

}


.spartan-modal {
  background-color: rgb(33,37,41);
  color: #fff;
}

.spartan-modal-backdrop {
  background-color: rgba(0,0,0, 0.8)
}

/* Custom CSS for dark accordion */
.dark-accordion {
  background-color: #343a40; /* Set your desired background color */
  color: #ffffff; /* Set your desired text color */
}

.dark-accordion .card-header {
  background-color: #212529; /* Set your desired header background color */
  color: #ffffff; /* Set your desired header text color */
}

.dark-accordion .card-header:hover {
  background-color: #454d55; /* Set your desired header background color on hover */
}

.dark-accordion .accordion-button {
  background-color: #343a40; /* Set your desired button background color */
  color: #ffffff; /* Set your desired button text color */
}

.dark-accordion .accordion-button:hover {
  background-color: #454d55; /* Set your desired button background color on hover */
}

.modal-90w {
  max-width: 90vw !important;
}

.qr-reader-container{
  max-width: 500px;
  margin: 0 auto;
}

.drop-area {
  text-align: center;
  width: 100%;
  height: 50px;
  background-color: grey;
}

.drop-area.over{
  background-color: turquoise;
}