.spartan-desktop-navigation {
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 75px;
    background-color: rgba(3, 72, 104, .75);
    z-index: 10;

    .option-container {
        height: 85vh;
        overflow-x: auto;
    }
    
    .toggle-button{
        position: absolute;
        right: -25px;
        background-color: black;
        border: none;
        color: white;
    }
    .menu-option{
        height: 75px;
        text-align: center;
        font-size: 24px;
        padding-top: 20px;
        cursor: pointer;

        .label {
            display: none;
        }
    }

    .logo-container {
        position: absolute;
        text-align: center;
        width: 75px;
        bottom: 30px;
    }

    &.open{
        width: 150px;

        .menu-option{
           
            font-size: 14px;
            .label{
                display: block;
            }
        }

        .logo-container{
            width: 150px;
        }
    }

    
}

.spartan-mobile-navigation {
    z-index: 100;
    width: 100vw;
    height: 75px;
    background-color:rgba(3, 72, 104, .75);
    position: fixed;
    top: 0px;
    left: 0px;
    .logo-container{
        width: 150px;
        position: absolute;
        top: 20px;
        left: 10px;
    }
    .toggle-button {
        position: absolute;
        right: 30px;
        top: 15px;
        color: #fff;
        border: 1px solid #fff;
        background-color: rgba(0,0,0,0);
        font-size: 24px;
    }

    .mobile-menu {
        z-index: 120;
        position: absolute;
        top: 75px;
        right: 20px;
        width: 200px;
        text-align: left;
        background-color: rgba(0,0,0,0.9);
        padding: 20px;

        .menu-option {
            margin-bottom: 20px;
            .label {
                margin-left: 10px;
            }
        }
    }

    .logo-container {

    }
}